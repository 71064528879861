<template>
  <div>
    <el-card shadow="never" style="height: 100%" body-style="height: 100%">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col
            ><el-row type="flex" justify="start"
              ><h4>Realizar um abastecimento</h4></el-row
            ></el-col
          >
        </el-row>
      </template>
      <el-empty
        v-show="!showStage"
        description="Escaneie o QRCode da ordem de abastecimento para iniciar"
        image="/images/qrcode.png"
      >
        <el-button type="primary" @click="showStage = 'readQr'"
          >Escanear QRCode</el-button
        >
      </el-empty>
      <div v-if="showStage === 'readQr'">
        <qrcode-stream
          :key="qrCodeReaderUid"
          :track="paintOutline"
          @decode="onDecode"
          @init="onInit"
        ></qrcode-stream>
        <el-row type="flex" justify="center">
          <h4 style="text-align: center">
            Segure a câmera em frente ao código QR da ordem
          </h4>
        </el-row>
      </div>

      <div v-show="showStage === 'supplyInfo'">
        <el-result
          v-show="!isValid"
          icon="error"
          title="Ordem de abastecimento inválida"
          subTitle="A ordem venceu ou foi desativada, não efetue o abastecimento"
        >
          <template #extra>
            <el-button type="primary" @click="showStage = null" size="medium"
              >Voltar</el-button
            >
          </template>
        </el-result>
        <el-descriptions :column="1" border v-show="isValid">
          <el-descriptions-item>
            <template #label> Fornecedor </template>
            {{ supply?.provider?.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Veículo </template>
            {{ supply?.car?.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Combustível </template>
            {{ supply?.fuel_formatted
            }}{{ supply?.fuel2 ? ", " + supply.fuel2_formatted : "" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Quantidade </template>
            {{ supply?.amount || "Completar o tanque" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Motorista </template>
            {{ supply?.driver?.employee?.name || "desconhecido" }}
          </el-descriptions-item>
        </el-descriptions>
        <h4>{{ execution?.supply_order?.obs }}</h4>
        <el-row
          v-show="isValid"
          type="flex"
          justify="center"
          style="margin-top: 50%"
        >
          <el-button
            @click="
              () =>
                (showStage = 'selfie') |
                (nextStage = 'odometer') |
                getCurrentPosition()
            "
            type="primary"
            >Realizar abastecimento</el-button
          >
        </el-row>
      </div>
      <div v-if="showStage === 'selfie'">
        <el-row type="flex" justify="center"
          ><h4>Confirme quem é você</h4></el-row
        >
        <div class="border-button">
          <el-row type="flex" justify="center">
            <div style="height: 450px">
              <div v-show="isCameraOpen" class="camera-canvas">
                <video
                  ref="camera"
                  :width="canvasWidth"
                  :height="canvasHeight"
                  autoplay
                ></video>
                <canvas
                  v-show="false"
                  id="photoTaken"
                  ref="canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
                ></canvas>
              </div>
            </div>
          </el-row>
        </div>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-camera"
            @click="() => capture('odometer')"
            >Enviar foto</el-button
          >
        </el-row>
      </div>
      <div v-if="showStage === 'odometer'">
        <el-row type="flex" justify="center"
          ><h4>Envie foto do odometro do veículo</h4></el-row
        >
        <div class="border-button">
          <el-row type="flex" justify="center">
            <div style="height: 450px">
              <div v-show="isCameraOpen" class="camera-canvas">
                <video
                  ref="camera"
                  :width="canvasWidth"
                  :height="canvasHeight"
                  autoplay
                ></video>
                <canvas
                  v-show="false"
                  id="photoTaken"
                  ref="canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
                ></canvas>
              </div>
            </div>
          </el-row>
        </div>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-camera"
            @click="() => capture('executionInfo')"
            >Enviar foto</el-button
          >
        </el-row>
      </div>
      <div v-show="showStage === 'executionInfo'">
        <el-row typ="flex" justify="center">
          <h4>Preencha os dados do abastecimento</h4>
        </el-row>
        <base-input
          v-model="execution.odometer"
          type="money"
          :minimumFractionDigits="0"
          label="Odômetro:"
        ></base-input>
        <div v-if="execution?.supply_order?.fuel">
          <el-row typ="flex" justify="start">
            <h6>{{ execution?.supply_order?.fuel_formatted }}</h6>
          </el-row>
          <base-input
            v-model="execution.quantity"
            type="money"
            label="Quantidade:"
          ></base-input>
          <base-input
            v-model="execution.amount"
            type="money"
            label="Valor:"
          ></base-input>
        </div>
        <div v-if="execution?.supply_order?.fuel2">
          <el-row typ="flex" justify="start">
            <h6>{{ execution?.supply_order?.fuel2_formatted }}</h6>
          </el-row>
          <base-input
            v-model="execution.quantity2"
            type="money"
            label="Quantidade:"
          ></base-input>
          <base-input
            v-model="execution.amount2"
            type="money"
            label="Valor:"
          ></base-input>
        </div>

        <div v-if="execution?.supply_order?.lubrification">
          <el-row typ="flex" justify="start">
            <h6>{{ execution?.supply_order?.lubrification_formatted }}</h6>
          </el-row>
          <base-input
            v-model="execution.lub_quantity"
            type="money"
            label="Quantidade:"
          ></base-input>
          <base-input
            v-model="execution.lub_amount"
            type="money"
            label="Valor:"
          ></base-input>
        </div>

        <el-row type="flex" justify="center" style="margin-top: 16px">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-upload"
            @click="save"
            >Enviar abastecimento</el-button
          >
        </el-row>
      </div>
      <el-result
        v-show="showStage === 'complete'"
        icon="success"
        title="Tudo Ok!"
        subTitle="Você enviou o abastecimento com sucesso"
      >
        <template #extra>
          <el-button type="primary" @click="showStage = null" size="medium"
            >Voltar</el-button
          >
        </template>
      </el-result>
      <el-result
        v-show="showStage === 'error'"
        icon="error"
        title="Ocorreu um erro!"
        subTitle="Algo não funcionou adequadamente, por favor tente novamente."
      >
        <template #extra>
          <el-button type="primary" @click="showStage = null" size="medium"
            >Voltar</el-button
          >
        </template>
      </el-result>
    </el-card>
    <!--<el-affix target="#app" position="bottom" v-show="showStage">
      <el-button
        @click="backStage"
        type="primary"
        icon="el-icon-arrow-left"
        size="large"
      ></el-button>
    </el-affix>-->
  </div>
</template>

<script>
//import Moment from "moment";
import { ElNotification } from "element-plus";
import BaseInput from "../components/BaseInput.vue";
import { QrcodeStream } from "vue3-qrcode-reader";
import { uuid } from "vue-uuid";
//import { camera } from "vue-capture";
export default {
  name: "BillsPage",
  components: {
    QrcodeStream,
    //camera,
    BaseInput,
  },
  data: () => ({
    canvasHeight: 450,
    canvasWidth: 400,
    qrCodeReaderUid: uuid.v4(),
    supply: null,
    execution: {},
    coordinates: null,
    images: [],
    loading: null,
    showStage: null,
    nextStage: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormat: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
  }),
  computed: {
    isCameraOpen() {
      const showCamera =
        this.showStage === "selfie" || this.showStage === "odometer";
      if (showCamera)
        this.startCameraStream(
          this.showStage === "selfie" ? "user" : "environment"
        );
      else this.stopCameraStream();
      return showCamera;
    },
    isValid() {
      let expires_at;
      if (this.execution) {
        expires_at = new Date(this.execution?.supply_order?.expires_at);
        expires_at?.setHours(23, 59);
        if (this.execution.status === "created" && new Date() < expires_at)
          return true;
      }

      onerror(
        "QRCode not valid",
        `this.execution = ${JSON.stringify({
          ...(this.execution || {}),
          now: new Date(),
          expires_at,
          agent: navigator.userAgent,
        })}`
      );
      return false;
    },
    Data() {
      return JSON.parse(this.$route?.query?.data || "{}");
    },
  },
  mounted() {
    if (this.$route.params.uid?.length) this.onDecode(this.$route.params.uid);
  },
  methods: {
    startCameraStream(mode = "user") {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: mode,
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.$message.error("O navegador não suporta acesso à câmera");
          this.showStage = this.nextStage;
        });
    },
    capture(nextStage) {
      const FLASH_TIMEOUT = 50;
      this.nextStage = nextStage;
      let self = this;
      setTimeout(() => {
        try {
          const context = self.$refs.canvas.getContext("2d");
          context.drawImage(
            self.$refs.camera,
            0,
            0,
            self.canvasWidth,
            self.canvasHeight
          );
          const dataUrl = self.$refs.canvas
            .toDataURL("image/jpeg")
            .replace("image/jpeg", "image/octet-stream");

          self.images.push(dataUrl);
          this.stopCameraStream();
        } finally {
          self.showStage = nextStage;
        }
      }, FLASH_TIMEOUT);
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    backStage() {
      this.showStage = null;
    },
    validateOdometer() {
      const lastControl = this.execution.car?.lastControl;
      let isValidOdometer = true;

      if (!this.execution.odometer) isValidOdometer = false;

      if (
        lastControl &&
        this.execution.odometer > Number(lastControl?.odometer)
      )
        isValidOdometer = false;

      if (
        lastControl &&
        this.execution.car.lastControl.odometer - this.execution.odometer > 500
      )
        isValidOdometer = false;

      return isValidOdometer;
    },
    validateQuantity() {
      const car = this.execution?.supply_order?.car;
      if (car?.max_quantity)
        return (
          Math.max(
            Number(this.execution.quantity),
            Number(this.execution.quantity2)
          ) < Number(car.max_quantity)
        );

      return true;
    },
    save() {
      if (!this.validateOdometer())
        return ElNotification.error({
          title: "Erro ao salvar",
          message: "O odômetro informado é inválido",
        });

      if (!this.validateQuantity())
        return ElNotification.error({
          title: "Erro ao salvar",
          message: "A quantidade informada é maior que o permitido",
        });

      this.updateExecution();
    },
    updateExecution() {
      this.showLoading("Enviando informações...");
      this.execution.selfie_image_url = this.images[0];
      this.execution.odometer_image_url = this.images[1];
      this.execution.latitude = this.coordinates?.latitude;
      this.execution.longitude = this.coordinates?.longitude;
      this.execution.status = "completed";
      fetch(
        `${this.$store.state.apiUrl}cars/supplies/execution/${this.execution.uid} `,
        {
          credentials: "include",
          method: `PUT`,
          body: JSON.stringify({ ...this.execution }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });

            if (this.$route.params.uid?.length) this.$router.push("/");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao salvar",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.closeLoading();
          this.showStage = "complete";
        });
    },
    async onInit(v) {
      try {
        this.showLoading("Abrindo a câmera...");
        await v;
      } catch (e) {
        this.showStage = null;
        let message = "";

        switch (e.name) {
          case "NotAllowedError":
            message = "Você precisa aceitar a utilização da câmera";
            break;
          case "NotFoundError":
            message = "A câmera não foi detectada";
            break;
          case "NotSupportedError":
          case "InsecureContextError":
            message = "Você deve acessar o site por HTTPS";
            break;
          case "NotReadableError":
            message = "A câmera está ocupada";
            break;
          case "OverconstrainedError":
            message = "A câmera não foi detectada";
            break;
          case "StreamApiNotSupportedError":
            message = "Este navegador não suporta acesso à câmera";
            break;
          default:
            message = "Não foi possível abrir a camera para escanear";
        }

        this.$message.error(message);
      } finally {
        const self = this;
        setTimeout(() => {
          self.closeLoading();
        }, 1000);
      }
    },
    async getCurrentPosition() {
      if ("geolocation" in navigator) {
        const self = this;
        navigator.geolocation.getCurrentPosition((position) => {
          self.coordinates = position.coords;
        });
      } else this.$message.error("Seu navegador não suporta geolocalização");
    },
    paintOutline(detectedCodes, ctx) {
      const {
        bottomLeftCorner,
        bottomRightCorner,
        topRightCorner,
        topLeftCorner,
      } = detectedCodes;

      makeAnSquare(
        [bottomLeftCorner, topLeftCorner, topRightCorner, bottomRightCorner],
        ctx
      );
    },
    onDecode(d) {
      if (isUuid(d)) {
        this.showLoading("Buscando a ordem de abastecimento");

        this.fetchSupplyOrder(d);
      } else this.showStage = "error";
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    showLoading(message = "Carregando... Aguarde.") {
      this.loading = this.$loading({
        fullscreen: true,
        lock: true,
        text: message,
      });
    },
    closeLoading() {
      if (this.loading) this.loading.close();
    },
    fetchSupplyOrder(uid) {
      const url = new URL(
        `${this.$store.state.apiUrl}cars/supplies/execution/${uid}`
      );
      if (uid)
        fetch(url, {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.supply = json?.supply_order;
            this.execution = json;
            this.showStage = "supplyInfo";
          })
          .catch(() => {
            this.showStage = "error";
          })
          .finally(() => this.closeLoading());
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
  },
};
function makeAnSquare(points, ctx) {
  ctx.lineWidth = 2;
  ctx.strokeStyle = "#007bff";
  ctx.beginPath();
  const firstPoint = points.pop();

  ctx.moveTo(firstPoint.x, firstPoint.y);

  for (const { x, y } of points) {
    ctx.lineTo(x, y);
  }

  ctx.lineTo(firstPoint.x, firstPoint.y);
  ctx.closePath();
  ctx.stroke();
}
const isUuid = (uuid) =>
  new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  ).test(`${uuid}`);
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.mt-2 {
  margin-top: 4px;
}
.p-0 {
  padding: 0px !important;
}
.border-button {
  border: 1px solid var(--el-border-color-base);
  border-radius: 0;
  padding: 4px;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.text-center {
  text-align: center !important;
}
@media print {
  table.el-table__body {
    width: auto !important;
  }
  table.el-table__header {
    width: auto !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
}
.el-icon-camera-solid {
  color: #dcdde0;
}
.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
.el-empty__image {
  opacity: 0.3;
}
</style>